import { Chat } from "@lobby/core/entities/chat";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";


export function UnreadBadge({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const { data: conversations } = Chat.useConversationList();
  const conversation = Array.isArray(conversations) ? conversations[0] : undefined;
  const isRead = conversation?.isRead ?? true;

  return !isRead ? (
    <div
      className={clsx(
        "w-8 h-8 rounded-full border-2 flex p-4 bg-radical-red border-transparent flex-center",
        className,
      )}
    >
      <p className="font-bold text-white text-center stroke-transparent">
        {$t({ defaultMessage: "New" }).toLowerCase()}
      </p>
    </div>
  ) : null;
}
