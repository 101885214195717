import { apiClient } from "@lobby/api-client";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "@shared/lib";

import type { SendOptions } from "@lobby/api-client";

export const Chat = {
  useConversationList() {
    const { isAuth } = useAuth();

    return useQuery({
      staleTime: 1000 * 30,
      refetchInterval: 1000 * 30,
      enabled: isAuth,
      queryKey: ["Conversation.getList"],
      queryFn: async () => {
        const response = await apiClient.send({ endpoint: "Conversation.getList" });

        if (response.error) {
          return {
            ...response,
            result: [],
          };
        }

        return response;
      },
      select: (data) => data.result || [],
    });
  },
  useSendMessage() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationKey: ["Conversation.sendMessage"],
      mutationFn: (params: SendOptions<"Conversation.sendMessage">["params"]) =>
        apiClient.send({
          endpoint: "Conversation.sendMessage",
          params,
        }),
      onSettled: async () =>
        await queryClient.invalidateQueries({ queryKey: ["Conversation.getMessages"] }),
    });
  },
  useConversationHistory(conversationId: number | undefined) {
    const { isAuth } = useAuth();

    return useInfiniteQuery({
      staleTime: 1000 * 30,
      refetchInterval: 1000 * 30,
      initialPageParam: 0,
      enabled: isAuth && conversationId !== undefined,
      queryKey: ["Conversation.getMessages", conversationId],
      queryFn: async ({ pageParam: seqNum }) => {
        const response = await apiClient.send({
          endpoint: "Conversation.getMessages",
          params: {
            conversationId: conversationId as number,
            startSeqNum: -seqNum,
            limit: 20,
          },
        });

        if (response.error) {
          response.result = response.result ?? [];
        }

        return response;
      },
      getNextPageParam: (lastPage, _allPages, _lastPageParam) => {
        const lastPageResult = lastPage.result;
        const lastSeqNum = lastPageResult[lastPageResult.length - 1]?.seqNum;

        if (lastPageResult.length === 0 || lastSeqNum === 0) {
          return undefined;
        }

        return lastSeqNum;
      },
      getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
        if (firstPageParam <= 1) {
          return undefined;
        }
        return firstPageParam - 1;
      },
    });
  },
};
