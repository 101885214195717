import { PlayerSettingsContextProvider } from "@entities/player";
import { LobbySettingsContextProvider } from "@lobby/core/entities/lobby";
import { localesMap } from "@shared/lib/locales";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { queryClient, router } from "./config";
import { JackpotContextProvider } from "./providers/jackpot-provider";
import { LocaleProvider } from "./providers/locale.provider";
import "./index.css";

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  // Set default EN locale if it's not set, or if locale in localStorage is not in localesMap,
  // due to the fact that it was removed from the app.
  const locale = localStorage.getItem("locale");
  if (!localesMap[locale as keyof typeof localesMap]) {
    localStorage.setItem("locale", "en");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <LobbySettingsContextProvider>
        <PlayerSettingsContextProvider>
          <JackpotContextProvider>
            <LocaleProvider>
              <RouterProvider router={router} />
            </LocaleProvider>
          </JackpotContextProvider>
        </PlayerSettingsContextProvider>
      </LobbySettingsContextProvider>
    </QueryClientProvider>
  );
}
