import { SupportChatBtn } from "@features/support-chat-btn";
import { Lobby } from "@lobby/core/entities/lobby";
import { clsx } from "clsx";
import { useState } from "react";
import { SupportChartWindow } from "./support-chat-window";
import { UnreadBadge } from "./unread-badge";

export function SupportChat({ className }: { className?: string }) {
  const [isMinimized, setIsMinimized] = useState(true);
  const { data: lobbyData } = Lobby.useLobby();

  const isConversationActive = lobbyData?.isIMActive ?? false;

  if (!isConversationActive) {
    return null;
  }

  return (
    <div className={clsx("relative max-w-full", className)}>
      {isMinimized ? (
        <>
          <SupportChatBtn
            className="absolute right-0 bottom-0"
            hasUnreadMessages={false}
            onClick={() => setIsMinimized(false)}
          />
          <UnreadBadge className="-right-3 absolute bottom-10" />
        </>
      ) : (
        <SupportChartWindow onMinimized={() => setIsMinimized(true)} />
      )}
    </div>
  );
}
