import { Chat } from "@lobby/core/entities/chat";
import { last } from "@lobby/core/shared/lib";
import { useLocale, useTranslate } from "@lobby/ocb-intl";
import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { Player } from "@entities/player";
import { APIError, useErrorTranslate } from "@shared/lib";
import { Button, SVGIcon } from "@shared/ui";
import { Spin } from "@shared/ui/spin";

import type { HTMLAttributes } from "react";

interface IConversationWindowProps {
  conversationId: number | undefined;
  pendingMessage: string | undefined;
  isChatWindowExpanded: boolean;
  onError: (isErrorVisible: boolean) => void;
  onMessagesLoading: (isMessagesLoaded: boolean) => void;
}

type TChatMessage = {
  seqNum: number;
  type: "text";
  text: string;
  createdAt: number;
  readAt: unknown;
  from: {
    userId: number;
    userName: string;
  };
};

export const ConversationWindow = memo(function ConversationWindow({
  conversationId,
  pendingMessage,
  isChatWindowExpanded,
  onError,
  onMessagesLoading,
}: IConversationWindowProps) {
  const [conversationError, setConversationError] = useState<APIError | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isScrollAtBottom = useRef(false);
  const lastScrollAreaHeight = useRef(0);

  const { data: playerData } = Player.usePlayer();
  const {
    data: messagesData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isMessagesLoading,
  } = Chat.useConversationHistory(conversationId);

  const locale = useLocale();
  const { $t } = useTranslate();

  const messages = useMemo(
    () => messagesData?.pages.flatMap((page) => page.result).reverse() ?? [],
    [messagesData],
  );
  const messagesExist = messages.length || pendingMessage;

  function scrollToBottom() {
    scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
  }

  useLayoutEffect(() => {
    const scrollContainer = scrollContainerRef.current!;
    if (!scrollContainer) return;

    if (!isFetchingNextPage) {
      scrollContainer.scrollTo(0, scrollContainer.scrollHeight - lastScrollAreaHeight.current);
    }

    lastScrollAreaHeight.current = scrollContainer.scrollHeight ?? 0;
  }, [isFetchingNextPage]);

  useLayoutEffect(() => {
    onMessagesLoading(!isMessagesLoading);
    scrollToBottom();
  }, [isMessagesLoading]);

  useLayoutEffect(() => {
    if (isScrollAtBottom.current || pendingMessage) {
      scrollToBottom();
    }
  }, [messages, pendingMessage, isChatWindowExpanded]);

  useEffect(() => {
    const error = last(messagesData?.pages)?.error;
    if (error) {
      setConversationError(new APIError(error.message, { code: error.code }));
    }
  }, [messagesData]);

  useEffect(() => {
    onError(Boolean(conversationError));
  }, [conversationError]);

  useEffect(() => {
    const handleScrollEvent = () => {
      const scrollContainer = scrollContainerRef.current!;
      isScrollAtBottom.current =
        scrollContainer.scrollTop + scrollContainer.clientHeight === scrollContainer.scrollHeight;

      if (scrollContainer.scrollTop === 0) {
        fetchNextPage();
      }
    };

    scrollContainerRef.current?.addEventListener("scroll", handleScrollEvent);
    return () => scrollContainerRef.current?.removeEventListener("scroll", handleScrollEvent);
  }, [scrollContainerRef.current, messagesExist]);

  return (
    <div className="relative min-h-0 grow rounded-t-rounded border-1 border-mercury border-b-0 bg-white dark:border-transparent dark:bg-outer-space">
      {messagesExist ? (
        <div
          className="scrollbar-thin custom-scrollbar gutter-stable relative h-full overflow-y-scroll overscroll-contain p-2"
          ref={scrollContainerRef}>
          {isFetchingNextPage && (
            <div className="pointer-events-none absolute top-5 right-0 left-0 flex items-center justify-center">
              <SVGIcon className="animate-spin text-keppel *:size-5 dark:text-java" name="reload" />
            </div>
          )}
          <ul id="chat-history-list">
            {messages.map((message) => (
              <ChatMessage
                key={message.seqNum}
                message={message}
                locale={locale}
                isFromCurrentUser={message.from.userId === playerData?.id}
              />
            ))}

            {pendingMessage && (
              <li data-owner="user" style={{ opacity: 0.5 }}>
                <div className="ml-auto flex w-fit flex-col rounded-rounded bg-keppel/20 px-1.5 py-1 text-sm dark:bg-java/20">
                  <span className="font-medium text-keppel dark:text-java">{playerData?.name}</span>
                  <p className="text-big-stone dark:text-white">{pendingMessage}</p>
                  <span className="self-end text-2xs text-big-stone dark:text-white">
                    {new Date(Date.now()).toLocaleTimeString(locale, {
                      timeStyle: "short",
                    })}
                  </span>
                </div>
              </li>
            )}
          </ul>
        </div>
      ) : isMessagesLoading ? (
        <div className="size-full flex-center flex-col">
          <Spin className="w-24" />
          <span className="mt-3 font-medium">{$t({ defaultMessage: "Loading..." })}</span>
        </div>
      ) : (
        <EmptyChatPlaceholder />
      )}

      {conversationError && (
        <ErrorPopup error={conversationError} onClose={() => setConversationError(null)} />
      )}
    </div>
  );
});

interface IChatMessageProps {
  message: TChatMessage;
  locale: string;
  isFromCurrentUser: boolean;
}

function formatDate(timestamp: number, locale: string) {
  const dt = new Date(timestamp);
  const diffDays = new Date().getDate() - dt.getDate();
  const diffMonths = new Date().getMonth() - dt.getMonth();
  const diffYears = new Date().getFullYear() - dt.getFullYear();

  const timeString = new Date(timestamp).toLocaleTimeString(locale, { timeStyle: "short" });
  const dateString = new Date(timestamp).toLocaleDateString(locale, { dateStyle: "short" });

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return timeString;
  } else {
    return timeString + ", " + dateString;
  }
}

function ChatMessage({ message, locale, isFromCurrentUser }: IChatMessageProps) {
  return (
    <li data-owner={isFromCurrentUser ? "user" : "support"}>
      <div
        className={`flex w-fit flex-col break-all rounded-rounded px-1.5 py-1 text-sm ${isFromCurrentUser ? "ml-auto bg-keppel/20 dark:bg-java/20" : "bg-athens-gray dark:bg-bright-gray"}`}>
        <span
          className={`font-medium ${isFromCurrentUser ? "text-keppel dark:text-java" : "text-steel-blue dark:text-[#98D5FF]"}`}>
          {message.from.userName}
        </span>
        <p className="text-big-stone dark:text-white">{message.text}</p>
        <span className="self-end text-2xs text-big-stone dark:text-white">
          {formatDate(message.createdAt * 1e3, locale)}
        </span>
      </div>
    </li>
  );
}

function ErrorPopup({ error, onClose }: { error: APIError; onClose: VoidFunction }) {
  const { $t } = useTranslate();
  const { formatMessage } = useErrorTranslate();

  return (
    <div className="absolute inset-0 flex-center">
      <div className="max-w-[90%] rounded-rounded border-1 border-radical-red bg-radical-red/30 p-1 shadow-[1px_1px_4px_#000000aa]">
        <div className="rounded-inherit bg-athens-gray p-2 dark:bg-big-stone">
          <div className="text-center font-bold text-radical-red text-sm lg:text-xl">
            {$t({ defaultMessage: "error" }).toUpperCase()}
          </div>
          <div className="font-bold text-big-stone text-sm lg:text-base dark:text-white">
            {formatMessage(error)}
          </div>
          <div className="text-big-stone text-xs lg:text-sm dark:text-white">
            <span>{$t({ defaultMessage: "Code:" })}</span>
            <span>{String(error.details.code)}</span>
          </div>
          <div className="text-big-stone text-xs lg:text-sm dark:text-white">
            <span>{new Date(Date.now()).toLocaleTimeString()}</span>
          </div>
          <Button className="mx-auto mt-1" onClick={onClose}>
            {$t({ defaultMessage: "ok" }).toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  );
}

interface IChatWindowControlBtnProps {
  className?: string;
  onClick: VoidFunction;
}

export function MinimizeWindowBtn({ className, onClick }: IChatWindowControlBtnProps) {
  return (
    <button
      className={`size-4 h-full text-big-stone lg:hover:text-keppel dark:text-white lg:dark:hover:text-java ${className}`}
      type="button"
      onClick={onClick}>
      <svg width="100%" height="100%" viewBox="0 0 16 16">
        <path d="M2 14H14" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
      </svg>
    </button>
  );
}

export function ExpandWindowBtn({ className, onClick }: IChatWindowControlBtnProps) {
  return (
    <button
      className={`size-4 text-big-stone lg:hover:text-keppel dark:text-white lg:dark:hover:text-java ${className}`}
      type="button"
      onClick={onClick}>
      <svg width="100%" height="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M2 3a1 1 0 0 1 1-1h4a1 1 0 0 0 0-2H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9a1 1 0 1 0-2 0v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3Zm9-3a1 1 0 1 0 0 2h1.59l-6.3 6.3a1 1 0 0 0 1.42 1.4L14 3.42V5a1 1 0 1 0 2 0V0h-5Z"
        />
      </svg>
    </button>
  );
}

export function SendMessageBtn({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
}) {
  return (
    <button
      className={`-translate-y-1/2 absolute top-1/2 right-2.5 h-[1.0625rem] w-[1.3125rem] text-nepal lg:hover:text-keppel dark:text-blue-bayoux lg:dark:hover:text-java ${
        disabled ? "pointer-events-none" : "pointer-events-auto"
      }`}
      disabled={disabled}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}>
      <svg width="100%" height="100%" viewBox="0 0 21 17">
        <path fill="currentColor" d="M21 0 4.9 17 2.56 8.25l9.81-5L1.37 5.33 0 0h21Z" />
      </svg>
    </button>
  );
}

export function EmptyChatPlaceholder() {
  const { $t } = useTranslate();

  return (
    <div className="size-full flex-center flex-col gap-4">
      <div className="relative size-28">
        <svg width="100%" height="100%" viewBox="0 0 112 112">
          <path
            className="fill-keppel dark:fill-java"
            d="m10.23 49.39-.25 1.7h6.82a4.1 4.1 0 0 1 4.1 4.1V84a4.1 4.1 0 0 1-4.1 4.1h-5.6c-5.46 0-9.7-4.1-9.7-8.9V56A54.56 54.56 0 0 1 56 1.5 54.56 54.56 0 0 1 110.5 56v23.2c0 4.8-4.25 8.9-9.7 8.9h-1.5v1.5c0 11.53-9.37 20.9-20.9 20.9H46.3V96.7h19.4v5.6h12.7c7 0 12.7-5.7 12.7-12.7V55.2a4.1 4.1 0 0 1 4.1-4.1h6.82l-.25-1.71A46.32 46.32 0 0 0 56 9.7a46.32 46.32 0 0 0-45.77 39.69Z"
          />
        </svg>
        <svg
          className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2"
          width="45%"
          height="45%"
          viewBox="0 0 52 30">
          <path
            className="fill-keppel dark:fill-java"
            d="M37.17 29.57a950.24 950.24 0 0 1 1.83-8.7l.84-1.05 1.55-7.37A1097.8 1097.8 0 0 1 43.18 4l6.02-2.63a1252.58 1252.58 0 0 0-5.27 5.84l-5.6 6.24a456.47 456.47 0 0 0-4.54 5.04l-.27-.99h8.2l.83-.57h8.84l-1.4 6.9h-24.1l1.1-5.36 2.26-2.52 2.52-2.82c.86-.93 1.64-1.8 2.36-2.6l4.34-4.85 2.27-2.52L43.01.63h8.34a4067.08 4067.08 0 0 0-2.12 10.1L47.1 20.85a5782.42 5782.42 0 0 0-1.82 8.72h-8.1ZM0 29.57l1.16-5.38a621 621 0 0 0 2.47-1.78 152.7 152.7 0 0 0 7.54-5.57 24.2 24.2 0 0 0 3.53-3.27 7.42 7.42 0 0 0 1.7-3.22c.24-1.06.08-1.93-.48-2.6-.55-.67-1.45-1-2.71-1-.62 0-1.27.08-1.95.26-.69.19-1.4.43-2.13.74-.72.3-1.47.66-2.22 1.07-.74.4-1.5.81-2.25 1.26l.95-7.98c.68-.3 1.39-.57 2.12-.82C8.46 1.03 9.2.81 10 .63A22.6 22.6 0 0 1 15.27 0c2.42 0 4.4.44 5.96 1.32a7.16 7.16 0 0 1 3.28 3.64c.63 1.52.73 3.26.31 5.2-.3 1.45-.85 2.78-1.64 4.02a17.18 17.18 0 0 1-3.23 3.6 44.47 44.47 0 0 1-5.19 3.79l-4.43 2.85-.82-1.74h12.86l-1.45 6.89H0Z"
          />
        </svg>
      </div>
      <p className="font-medium text-keppel dark:text-java">
        {$t({ defaultMessage: "Have a question? Ask us!" })}
      </p>
    </div>
  );
}
