import { CategoryIcon } from "@features/category/category-icon";
import { LanguageSelector } from "@features/language-selector";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { PlayerInfo } from "@features/player";
import { ThemeSwitch } from "@features/theme-switch";
import { useTranslate } from "@lobby/ocb-intl";
import { useAuth, useModal } from "@shared/lib";
import { SVGIcon } from "@shared/ui";
import { Button } from "@shared/ui/button";
import { Logo } from "@shared/ui/logo";
import { Link, useSearch } from "@tanstack/react-router";

import { useHeaderCategories } from "../lib";

export function Header() {
  const headerCategories = useHeaderCategories();

  const { $t } = useTranslate();

  const { open: openLogInModal } = useModal("log-in");
  const categoryId = useSearch({ strict: false, select: ({ cid }) => cid });
  const { isAuth } = useAuth();

  // const hasContactsButton = lobby.data?.buttons?.contacts?.enabled;

  return (
    <header className="sticky top-0 z-10 bg-mercury lg:h-17.5 dark:bg-outer-space">
      <div className="mx-auto flex h-full mobile-only:flex-col justify-between lg:max-w-screen-3xl lg:px-5">
        <nav className="scrollbar-thin mobile-only:order-2 flex lg:h-full lg:items-center lg:overflow-x-auto">
          <div className="mobile-only:hidden">
            <Link to="/">
              <Logo className="h-11" />
            </Link>
          </div>
          <ul className="scrollbar-thin flex mobile-only:w-full mobile-only:overflow-x-auto font-bold mobile-only:text-xs uppercase lg:h-full lg:items-stretch lg:px-5">
            <li className="mobile-only:h-8 mobile-only:flex-1">
              <Link
                className="flex h-full items-center mobile-only:justify-center gap-1.5 px-3 lg:gap-2.5 lg:px-7.5"
                activeProps={{
                  className: "bg-keppel text-white dark:bg-bright-gray dark:text-java",
                }}
                to="/game/$gameId"
                params={{
                  gameId: "sport",
                }}>
                <SVGIcon name="americanFootball" className="size-5 lg:size-6" />
                {$t({ defaultMessage: "Sport" })}
              </Link>
            </li>
            <li className="mobile-only:h-8 mobile-only:flex-1">
              <Link
                className="flex h-full items-center mobile-only:justify-center gap-1.5 px-3 lg:gap-2.5 lg:px-7.5"
                activeOptions={{ exact: headerCategories.some(({ id }) => id === categoryId) }}
                activeProps={{
                  className: "bg-keppel text-white dark:bg-bright-gray dark:text-java",
                }}
                to="/casino">
                <SVGIcon name="cardHearts" className="size-5 lg:size-6" />
                {$t({ defaultMessage: "Casino" })}
              </Link>
            </li>

            {headerCategories.map(({ id, name }) => (
              <li className="mobile-only:h-8 mobile-only:flex-1" key={id}>
                <Link
                  className="flex h-full items-center mobile-only:justify-center gap-1.5 px-3 lg:gap-2.5 lg:px-7.5"
                  activeProps={{
                    className: "bg-keppel text-white dark:bg-bright-gray dark:text-java",
                  }}
                  to="/casino"
                  search={{ cid: id }}>
                  <CategoryIcon className="*:size-5 lg:*:size-6" id={id} />
                  {name}
                </Link>
              </li>
            ))}
            {isAuth && (
              <li className="mobile-only:h-8 mobile-only:flex-1">
                <Link
                  className="flex h-full items-center mobile-only:justify-center gap-1.5 px-3 lg:gap-2.5 lg:px-7.5"
                  search={(prev) => ({
                    ...prev,
                    modal: "profile",
                    profileSection: "sportBetsHistory",
                  })}>
                  <SVGIcon name="trophy" className="size-5 lg:size-6" />
                  {$t({ defaultMessage: "Sport Bets history" })}
                </Link>
              </li>
            )}
            {/* {hasContactsButton && (
              <li className="mobile-only:flex-1 mobile-only:h-8">
                <Link
                  className="lg:gap-2.5 lg:px-7.5 px-3 flex items-center gap-1.5 mobile-only:justify-center h-full"
                  search={(prev) => ({ ...prev, modal: "contacts" })}
                  replace
                >
                  <SVGIcon name="headset" className="lg:size-6 size-5" />
                  {$t({ defaultMessage: "Contacts" })}
                </Link>
              </li>
            )} */}
          </ul>
        </nav>
        <div className="mobile-only:order-1 flex items-center mobile-only:justify-between mobile-only:p-3 lg:h-full lg:gap-5">
          <div className="flex items-center gap-2.5 lg:hidden">
            <MobileMenuButton />
            <Link to="/">
              <Logo className="h-7" />
            </Link>
          </div>

          <LanguageSelector className="mobile-only:hidden" />
          <ThemeSwitch className="mobile-only:hidden" />
          <div className="flex h-full items-center gap-5 lg:contents">
            {isAuth ? (
              <PlayerInfo />
            ) : (
              <Button onClick={openLogInModal}> {$t({ defaultMessage: "sign in" })}</Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
