import { ensureUserIsAuthenticated, validateSearchProfileSection } from "@app/lib";
import { loadFortuneWheelSettings } from "@app/lib/loaders";
import { SuccessModal } from "@features/success-modal";
import { WarningModal } from "@features/warning-modal";
import { ContactsModal } from "@modals/contacts-modal/contacts-modal";
import { TakeBonusModal } from "@modals/take-bonus/take-bonus-modal";
import { emitter } from "@shared/lib";
import { ErrorModal } from "@shared/ui/error-modal/error-modal";
import type { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  Navigate,
  Outlet,
  createRootRouteWithContext,
  defer,
  useNavigate,
} from "@tanstack/react-router";
import { ProfileModal, type TProfileSection } from "@widget/profile-modal";
import { useEffect } from "react";

type GlobalSearch = {
  modal?: string;
  cid: number;
  pid: number;
  game: string;
  redirect: string;
  profileSection?: TProfileSection;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  onError: console.error,
  beforeLoad: async ({ cause }) => cause === "enter" && (await ensureUserIsAuthenticated()),
  loader: async ({ context }) => defer(loadFortuneWheelSettings(context.queryClient)),
  notFoundComponent: () => <Navigate to="/" replace params={{}} />,
  validateSearch: (search: Record<string, unknown>): GlobalSearch => ({
    modal: search.modal as string,
    cid: search.cid as number,
    pid: search.pid as number,
    game: search.game as string,
    redirect: search.redirect as string,
    profileSection: validateSearchProfileSection(search),
  }),
});

function RootComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    return emitter.on("PLAYER_LOGOUT", () => navigate({ to: "/", replace: true }));
  }, [navigate]);

  return (
    <>
      <ProfileModal />
      <TakeBonusModal />
      <ContactsModal />
      <SuccessModal />
      <WarningModal />
      <ErrorModal />
      <div id="message-container" />

      <Outlet />
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </>
  );
}
