import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

import { router } from "@app/config";
import { emitter, useModal } from "@shared/lib";
import { LogInModal } from "@widget/log-in-modal";
import { PasswordForgotModal } from "@widget/password-forgot-modal";
import { RegisterModal } from "@widget/register-modal";

export function SignInPage() {
  const { open: openLogInModal } = useModal("log-in");
  const search = useSearch({ strict: false });

  useEffect(() => {
    const unbind = emitter.on("PLAYER_LOGIN", () => router.history.push(search.redirect || "/"));
    return unbind;
  }, []);

  useEffect(() => openLogInModal(), [openLogInModal]);

  return (
    <div className="fixed inset-0">
      <LogInModal isStatic />
      <RegisterModal isStatic />
      <PasswordForgotModal isStatic />
    </div>
  );
}
