import { LobbySettingsContext } from "@lobby/core/entities/lobby";
import { getProviderImageSrc } from "@shared/lib";
import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { type PropsWithChildren, useContext } from "react";

interface IProviderButtonProps {
  className?: string;
  name: string;
  id: number;
  isActive?: boolean;
  stringId: string;
  onClick?: () => void;
}

export function ProviderButton({
  className,
  name,
  id,
  isActive,
  stringId,
  onClick,
}: PropsWithChildren<IProviderButtonProps>) {
  const navigate = useNavigate();
  const lobbyContext = useContext(LobbySettingsContext);

  return (
    <button
      className={clsx(
        className,
        isActive ? "border-keppel dark:border-java" : "border-transparent",
        "flex items-center justify-center rounded-rounded border-1 bg-mercury *:size-full dark:bg-bright-gray",
      )}
      type="button"
      title={name}
      onClick={() => {
        navigate({
          replace: true,
          search: (prev) => ({
            ...prev,
            game: undefined,
            cid: undefined,
            pid: prev.pid === id ? undefined : id,
          }),
        });

        if (onClick) {
          onClick();
        }
      }}>
      <img src={getProviderImageSrc(lobbyContext.theme, stringId)} alt={stringId} />
    </button>
  );
}
